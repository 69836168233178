<script lang="ts">
  interface Props {
    class?: string;
    children?: import("svelte").Snippet;
  }

  let { class: classes = "", children }: Props = $props();
</script>

<ul class="list-checkmarks {classes}">
  {@render children?.()}
</ul>

<style lang="postcss">
  .list-checkmarks {
    --_stroke-w: 3px;
    padding-inline-start: 0;
    list-style: none !important;
  }

  :global(:where(.list-checkmarks)) {
    margin-block: var(--s-6);
  }

  .list-checkmarks > :global(li) {
    position: relative;
    padding-inline-start: var(--checkmark-space, 2em);
    margin-block-end: var(--s-3);
  }

  .list-checkmarks > :global(li::before),
  .list-checkmarks > :global(li::after) {
    content: "";
    position: absolute;
    background-color: var(--checkmark-color, var(--primary-strong));
    border-radius: 100vw;
  }

  .list-checkmarks > :global(li::before) {
    width: 9px;
    height: var(--_stroke-w);
    inset: 11px auto auto calc(2px + var(--_stroke-w));
    transform: rotate(45deg) translateY(5px);
  }

  .list-checkmarks > :global(li::after) {
    height: 18px;
    width: var(--_stroke-w);
    inset: 0px auto auto 9px;
    transform: rotate(45deg) translateX(5px);
  }
</style>
